import { useState, useEffect } from 'react';
import { init, track } from '@streamelements/alchemy';
import logo from './assets/fortnite-logo.png';
import chars from './assets/desktop-epic-connect-chars-3.png';
import texts from './texts';
import modalClose from './assets/close.svg';
import { transformVars } from './transformVars';

function App() {
  const [modal, setModal] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const lang = query.get('lang') ? query.get('lang') : 'en';
  const campaign = query.get('campaign') ? query.get('campaign') : 'sp';
  const getLink = () => {
    if(process.env.NODE_ENV === 'development'){
      return 'http://localhost:9181/auth?' + query.toString()
    }else if(process.env.NODE_ENV === 'production'){
      if(window.location.href.includes('staging')) {
        return 'https://epicgames.staging.streamelements.com/auth?' + query.toString()
      }else{
        return 'https://epicgames.streamelements.com/auth?' + query.toString()
      } 
    }
  }

  const privacyPolicy = 'https://streamelements.com/privacy';

  useEffect(() => {
    init()
  }, []);

  const openModal = () => {
    setModal(true)
    track({
      name: 'landing_page_play_click',
      event: 'landing_page_play_click',
      source: 'leaderboard',
      feature: 'leaderboard',
      product: 'sponsorship',
      fields: [['partnershipid', 'leaderboard-test']],
    });
  }

  const allowClick = () => {
    track({
      name: 'allow_permissions_click',
      event: 'allow_permissions_click',
      source: 'leaderboard',
      feature: 'leaderboard',
      product: 'sponsorship',
      fields: [['partnershipid', 'leaderboard-test']],
    });
  }

  return (
    <div className={`wrapper ${lang === 'ru' ? 'russian' : ''}`}>
      <div className="content">
        <img className="logo" src={logo} alt="Fortnite logo"/>
        {texts[campaign][lang].subtitle && <p className="subtitle">{texts[campaign][lang].subtitle}</p>}
        <p>{texts[campaign][lang].mainText}</p>
        <button 
          className="link-wrapper" 
          onClick={openModal}
        >       
          {texts[campaign][lang].btnText}
      </button>
      </div>
      <div className="heroImgWrapper">
        <img src={chars} alt="Fortnite characters"/>
      </div>
      {modal &&
        <>
          <div className="modalOverlay"/>
          <div className="modalWrapper">
            <div className="modal">
            <button className="closeBtn" onClick={() => setModal(false)}>
              <img
                src={modalClose}
                alt="close"
              />
            </button>
              <p className="modalP">
                {texts[campaign][lang].modalFirstPart}
                <ul>
                  {texts[campaign][lang].modalPermissions.map((permission) => (
                    <li className="modalList">{permission}</li>
                  ))}
                </ul>
              </p>
              {texts[campaign][lang].modalSecondPart.map((part) => (<p className="modalP">{transformVars(part, [(link) => (<a href={privacyPolicy} rel='noreferrer noopener' target="_blank">{link}</a>)])}</p>))}
              <div className="modalActions">
                <button className="modalDenyButton" onClick={() => setModal(false)}>
                  {texts[campaign][lang].modalDenyButton}
                </button>
                <a className="modalAcceptButton" 
                  href={getLink()}
                  onClick={allowClick}
                >
                  {texts[campaign][lang].modalApproveButton}
                </a>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default App;
