const texts =  {
  cap: {
    en: {
      mainText: 'Play Fortnite for free and earn awesome in-game rewards!',
      btnText: 'Join',
      modalFirstPart: 'StreamElements is requesting permission to access information from your Epic Games account including the following:',
      modalPermissions: ['Epic Games display name', 'Game play statistics, which may include eliminations, games played, Victory Royales, etc.'],
      modalSecondPart: 'By clicking Allow below, you agree that Epic Games can share the above information with StreamElements, Inc. to enable “Leaderboard Challenge”, which may display the above information on the Leaderboard Challenge Website and in your connected Fortnite streamer’s live, on-stream leaderboard.',
      modalApproveButton: 'ALLOW',
      modalDenyButton: 'CANCEL',
    },
    ru: {
      mainText: 'Играйте в Fortnite бесплатно и получайте отличные награды в игре!',
      btnText: 'Присоединиться',
      modalFirstPart: 'StreamElements запрашивает доступ к следующим данным вашей учётной записи Epic Games:',
      modalPermissions: ['отображаемое имя Epic Games;', 'игровая статистика игрока, которая может включать устранения, сыгранные матчи, королевские победы и пр.'],
      modalSecondPart: 'Принимая условия соглашения, вы разрешаете Epic Games предоставить указанную выше информацию StreamElements, Inc. в целях активации «Leaderboard Challenge»; упомянутая выше информация может быть отображена на веб-сайте «Leaderboard Challenge» и на специальном окне во время прямых трансляций выбранного стримера.',
      modalApproveButton: 'Разрешить',
      modalDenyButton: 'Отмена',
    },
    de: {
      mainText: 'Spiele Fortnite, um kostenlose und tolle Belohnungen im Spiel zu verdienen!',
      btnText: 'Beitreten',
      modalFirstPart: 'StreamElements benötigt die Berechtigung, auf Informationen deines Epic Games-Kontos zuzugreifen, einschließlich auf Folgendes:',
      modalPermissions: ['Epic Games-Anzeigename', 'Spielstatistiken, die unter anderem die Anzahl an Eliminierungen, gespielten Matches und epischen Siegen enthalten können'],
      modalSecondPart: 'Wenn du unten auf „Erlauben“ klickst, erklärst du dich damit einverstanden, dass Epic Games die obigen Informationen mit StreamElements, Inc. teilen darf, um so die „Leaderboard Challenge“ zu ermöglichen, wobei die obigen Informationen gegebenenfalls auf der Webseite der Leaderboard Challenge und auf dem Livestream-Leaderboard deines verbundenen Fortnite-Streamers angezeigt werden.',
      modalApproveButton: 'Erlauben',
      modalDenyButton: 'Abbrechen',
    },
    it: {
      mainText: 'Gioca a Fortnite gratis e guadagna fantastici premi in gioco!',
      btnText: 'Unisciti',
      modalFirstPart: 'StreamElements richiede di accedere a dati del tuo account Epic Games, compresi:',
      modalPermissions: ['Nome visualizzato Epic Games', 'Statistiche di gioco, che possono includere eliminazioni, partite giocate, vittorie reali, ecc.'],
      modalSecondPart: 'Facendo clic su Consenti, qui sotto, accetti che Epic Games possa condividere le informazioni sopra elencate con StreamElements, Inc. per abilitare la "Leaderboard Challenge", che può mostrare le informazioni sopra elencate sul sito della Leaderboard Challenge e nella tua classifica collegata di Fortnite, visualizzabile in diretta durante lo streaming.',
      modalApproveButton: 'Consenti',
      modalDenyButton: 'Annulla',
    },
  },
  sp: {
    en: {
      mainText: 'Play Fortnite for free and earn awesome in-game rewards!',
      btnText: 'Join',
      modalFirstPart: 'StreamElements is requesting permission to access information from your Epic Games account including the following:',
      modalPermissions: ['Epic Games display name', 'Game play statistics, which may include eliminations, games played, Victory Royales, etc.'],
      modalSecondPart: 'By clicking Allow below, you agree that Epic Games can share the above information with StreamElements, which may display the above information.',
      modalApproveButton: 'ALLOW',
      modalDenyButton: 'CANCEL',
    },
    ru: {
      mainText: 'Играйте в Fortnite бесплатно и получайте отличные награды в игре!',
      btnText: 'Присоединиться',
      modalFirstPart: 'StreamElements запрашивает доступ к следующим данным вашей учётной записи Epic Games:',
      modalPermissions: ['отображаемое имя Epic Games;', 'игровая статистика игрока, которая может включать устранения, сыгранные матчи, королевские победы и пр.'],
      modalSecondPart: 'Принимая условия соглашения, вы разрешаете Epic Games предоставить данную информацию StreamElements, Inc., которая может отображать вышеуказанную информацию.',
      modalApproveButton: 'Разрешить',
      modalDenyButton: 'Отмена',
    },
    de: {
      mainText: 'Spiele Fortnite, um kostenlose und tolle Belohnungen im Spiel zu verdienen!',
      btnText: 'Beitreten',
      modalFirstPart: 'StreamElements möchte Zugriff auf die folgenden Epic Games Account Informationen:',
      modalPermissions: ['Epic Games-Anzeigename', 'Spielstatistiken, die unter anderem die Anzahl an Eliminierungen, gespielten Matches und epischen Siegen enthalten können'],
      modalSecondPart: 'Wenn du unten auf „Erlauben“ klickst, erklärst du dich damit einverstanden, dass Epic Games die obigen Informationen mit StreamElements, Inc. teilt und deine Informationen im Event angezeigt werden.',
      modalApproveButton: 'Erlauben',
      modalDenyButton: 'Abbrechen',
    },
    it: {
      mainText: 'Gioca a Fortnite gratis e guadagna fantastici premi in gioco!',
      btnText: 'Unisciti',
      modalFirstPart: 'StreamElements richiede di accedere a dati del tuo account Epic Games, compresi:',
      modalPermissions: ['Nome visualizzato Epic Games', 'Statistiche di gioco, che possono includere eliminazioni, partite giocate, vittorie reali, ecc.'],
      modalSecondPart: 'Facendo clic su Consenti qui sotto, accetti che Epic Games possa condividere le informazioni sopra elencate con StreamElements, Inc. che potrà poi mostrare le informazioni sopra elencate.',
      modalApproveButton: 'Consenti',
      modalDenyButton: 'Annulla',
    },
  },
  nobr_kill: {
    en: {
      mainText: 'Play Fortnite\'s new No Build Mode and Earn!',
      btnText: 'Connect',
      modalFirstPart: 'StreamElements is requesting permission to access information from your Epic Games account including the following:',
      modalPermissions: ['Epic Games display name', 'Game play statistics, which may include eliminations, games played, Victory Royales, etc.'],
      modalSecondPart: 'By clicking Allow below, you agree that Epic Games can share the above information with StreamElements, which may display the above information.',
      modalApproveButton: 'ALLOW',
      modalDenyButton: 'CANCEL',
    },
    ru: {
      mainText: 'Играйте в Fortnite бесплатно и получайте отличные награды в игре!',
      btnText: 'Присоединиться',
      modalFirstPart: 'StreamElements запрашивает доступ к следующим данным вашей учётной записи Epic Games:',
      modalPermissions: ['отображаемое имя Epic Games;', 'игровая статистика игрока, которая может включать устранения, сыгранные матчи, королевские победы и пр.'],
      modalSecondPart: 'Принимая условия соглашения, вы разрешаете Epic Games предоставить данную информацию StreamElements, Inc., которая может отображать вышеуказанную информацию.',
      modalApproveButton: 'Разрешить',
      modalDenyButton: 'Отмена',
    },
    de: {
      mainText: 'SPIELE FORTNITE KOSTENLOS UND VERDIENE EINE TOLLE BELOHNUNG IM SPIEL!',
      btnText: 'Beitreten',
      modalFirstPart: 'StreamElements möchte Zugriff auf die folgenden Epic Games Account Informationen:',
      modalPermissions: ['Epic Games-Anzeigename', 'Spielstatistiken, die unter anderem die Anzahl an Eliminierungen, gespielten Matches und epischen Siegen enthalten können'],
      modalSecondPart: 'Wenn du unten auf „Erlauben“ klickst, erklärst du dich damit einverstanden, dass Epic Games die obigen Informationen mit StreamElements, Inc. teilt und deine Informationen im Event angezeigt werden.',
      modalApproveButton: 'Erlauben',
      modalDenyButton: 'Abbrechen',
    },
    it: {
      mainText: 'GRAJ W FORTNITE ZA DARMO I ZDOBĄDŹ NAGRODĘ W GRZE!',
      btnText: 'Unisciti',
      modalFirstPart: 'StreamElements richiede di accedere a dati del tuo account Epic Games, compresi:',
      modalPermissions: ['Nome visualizzato Epic Games', 'Statistiche di gioco, che possono includere eliminazioni, partite giocate, vittorie reali, ecc.'],
      modalSecondPart: 'Facendo clic su Consenti qui sotto, accetti che Epic Games possa condividere le informazioni sopra elencate con StreamElements, Inc. che potrà poi mostrare le informazioni sopra elencate.',
      modalApproveButton: 'Consenti',
      modalDenyButton: 'Annulla',
    },
  },
  bp_level: {
    en: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: 'Play Fortnite and earn an in-game Wrap for your gear!',
      btnText: 'Join',
      modalFirstPart: 'StreamElements is requesting permission to access information from your Epic Games account including:', 
      modalPermissions: ['Epic Games display name', 'Epic Games Account ID', 'Game play statistics'],
      modalSecondPart: [
        'By clicking Allow below, you agree that Epic Games can share the above information with StreamElements, which may display the above information. You will also be redirected to the StreamElements website.',
        'To learn more about StreamElements\' privacy practices, click %l0{here}%l.'
      ],
      modalApproveButton: 'ALLOW',
      modalDenyButton: 'CANCEL',
    },
    fr: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: 'JOUEZ A FORTNITE ET OBTENEZ UN REVÊTEMENT POUR VOTRE ÉQUIPEMENT DANS LE JEU !',
      btnText: 'Participer',
      modalFirstPart: 'StreamElements requiert votre autorisation pour accéder aux informations de votre compte Epic Games, parmi lesquelles :',
      modalPermissions: [
        'Votre pseudo Epic Games', 'L\'identifiant de votre compte Epic Games', 'Vos statistiques de jeu',
      ],
      modalSecondPart: [
        'En cliquant sur Autoriser ci-dessous, vous acceptez qu\'Epic Games partage les informations ci-dessus avec StreamElements et permettez l\'affichage de ces informations. Cela vous redirigera vers le site Internet de StreamElements.',
        'Pour en savoir plus sur la politique de confidentialité de StreamElements, cliquez %l0{ici}%l.'
      ],
      modalApproveButton: 'AUTORISER',
      modalDenyButton: 'ANNULER',
    },
    pt: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: 'JOGUE FORTNITE E GANHE UM ENVELOPAMENTO PARA OS SEUS EQUIPAMENTOS!',
      btnText: 'Participar',
      modalFirstPart: 'StreamElements está solicitando permissão para acessar informações sobre a sua conta Epic Games, incluindo:',
      modalPermissions: ['Nome de exibição Epic Games', 'ID da Conta Epic Games', 'Estatísticas de Jogo'],
      modalSecondPart: [
        'Ao clicar em "Permitir" abaixo, você concorda que a Epic Games possa compartilhar as informações acima com a StreamElements, que poderá exibi-las. Você também será redirecionado para o site da StreamElements.',
        'Clique %l0{aqui}%l para saber mais sobre as práticas de privacidade da StreamElements.'
      ],
      modalApproveButton: 'PERMITIR',
      modalDenyButton: 'CANCELAR',
    },
    es: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: '¡JUEGA A FORTNITE Y GANA UN ENVOLTORIO INCREÍBLE PARA TU EQUIPAMIENTO!',
      btnText: 'Unirse',
      modalFirstPart: 'StreamElements solicita permiso para acceder a la siguiente información de tu cuenta de Epic Games:',
      modalPermissions: ['Nombre de usuario de Epic Games', 'ID de la cuenta de Epic Games', 'Estadísticas de juego'],
      modalSecondPart: [
        'Al hacer clic en Permitir, aceptas que Epic Games comparta la información anterior con StreamElements, que podría mostrar la información descrita anteriormente. También se te redirigirá al sitio web de StreamElements.',
        'Haz clic %l0{aquí}%l para obtener más información sobre las prácticas de privacidad de StreamElements.'
      ],
      modalApproveButton: 'PERMITIR',
      modalDenyButton: 'CANCELAR',
    },
    de: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: 'Spiele Fortnite kostenlose und verdiene eine tolle Belohnungen im Spiel!',
      btnText: 'Beitreten',
      modalFirstPart: 'StreamElements möchte Zugriff auf die folgenden Epic Games Account Informationen:',
      modalPermissions: ['Epic Games-Anzeigename', 'Spielstatistiken, die unter anderem die Anzahl an Eliminierungen, gespielten Matches und epischen Siegen enthalten können'],
      modalSecondPart: [
        'Wenn du unten auf „Erlauben“ klickst, erklärst du dich damit einverstanden, dass Epic Games die obigen Informationen mit StreamElements, Inc. teilt und deine Informationen im Event angezeigt werden.'
      ],
      modalApproveButton: 'Erlauben',
      modalDenyButton: 'Abbrechen',
    },
    pl: {
      subtitle: '"Welcome to Fortnite" challenge',
      mainText: 'Graj w Fortnite za darmo i zdobywaj nagrody w grze!',
      btnText: 'Dołącz',
      modalFirstPart: 'StreamElements wymaga dostępu do informacji z Twojego konta Epic Games, w tym:',
      modalPermissions: ['Nazwa wyświetlana Epic Games', 'Statystyki gry, które mogą zawierać eliminacje, gry, wygrane w roli króla, itp.'],
      modalSecondPart: [
        'Klikając na Zezwól poniżej, zgadzasz się, że Epic Games może udostępnić powyższe informacje z StreamElements, który może pokazać powyższe informacje.'
      ],
      modalApproveButton: 'Zezwól',
      modalDenyButton: 'Anuluj',
    },
  }
}

export default texts