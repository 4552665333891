export function transformVars(
  stringTemplate,
  transformers
) {
  if (!stringTemplate) {
    return "";
  }

  const regex = /(%l.*?%l)/;
  const innerRegex = /%l\d{(.*?)}%l/;
  const indexRegex = /%l(\d).*%l/;

  return stringTemplate.split(regex).map((str) => {
    const match = regex.exec(str);

    if (!match) {
      return str;
    }

    const wordToReplaceWithComponent = match[1];

    const index = indexRegex.exec(wordToReplaceWithComponent)?.[1];
    const value = wordToReplaceWithComponent.replace(innerRegex, "$1");
    const transform = transformers[Number(index)];
    if (!transform) {
      return value;
    }

    return transform(value);
  });
}
